<template>
  <v-container fluid pa-8>
    <!-- Filtros y botones -->
    <v-row>
      <!--Filtro Fecha -->
      <v-col cols="12" sm="6">
        <dateRange
          v-model="filter.date"
          default="Últimos 30 días"
          ref="dateRange"
          dense
        />
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-space-around">
        <v-btn
          @click="cleanFilters()"
          class=""
          color="grey lighten-2 grey--text text--darken-4"
        >
          Limpiar Filtros
        </v-btn>

        <v-btn
          @click="fetchDataByReport()"
          class=""
          color="primary white--text"
        >
          Filtrar
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="showTable = !showTable"
              color="#393862"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark large>
                {{ showTable ? "mdi-chart-areaspline" : "mdi-table" }}
              </v-icon>
            </v-btn>
          </template>
          <span>Mostrar gráfica</span>
        </v-tooltip>
        <v-tooltip bottom v-if="showTable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="makePdf()"
              color="#393862"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark large>
                mdi-file-export-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Exportar como PDF</span>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="rotateGraphic()"
              color="#393862"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark large :class="rotate ? 'flip-icon' : ''">
                mdi-rotate-left-variant
              </v-icon>
            </v-btn>
          </template>
          <span>Rotar Grafica</span>
        </v-tooltip>
      </v-col>
      <!--Filtro Fuente -->
      <v-col cols="12" sm="4">
        <v-autocomplete
          outlined
          prepend-inner-icon="mdi-filter-menu-outline"
          v-model="filter.sources"
          :items="sources"
          item-text="name"
          item-value="_id"
          label="Filtrar por fuente"
          multiple
          chips
          deletable-chips
          small-chips
        >
          <template v-slot:append-outer>
            <v-slide-x-reverse-transition mode="out-in">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="() => {}" v-bind="attrs" v-on="on">
                    mdi-find-replace
                  </v-icon>
                </template>
                <span>Invertir selección</span>
              </v-tooltip>
            </v-slide-x-reverse-transition>
          </template>
        </v-autocomplete>
      </v-col>
      <!--Filtro Zonas -->
      <v-col cols="12" sm="4">
        <v-autocomplete
          outlined
          prepend-inner-icon="mdi-filter-menu-outline"
          v-model="filter.zones"
          :items="zones"
          label="Filtrar por Desarrollos"
          multiple
          chips
          deletable-chips
          small-chips
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          outlined
          prepend-inner-icon="mdi-filter-menu-outline"
          v-model="filter.campaigns"
          :items="campaignList"
          label="Filtrar por Campañas"
          multiple
          chips
          deletable-chips
          small-chips
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!-- switch para mostrar diferentes datos -->
    <div v-if="showTable && data.length > 0" class="list_options">
      <v-switch
        color="primary"
        v-model="show.type"
        flat
        label="Tipos"
      ></v-switch>

      <v-switch
        color="primary"
        v-model="show.campaign"
        flat
        label="Campañas"
      ></v-switch>

      <v-switch
        color="primary"
        v-model="show.medium"
        flat
        label="Medios"
      ></v-switch>

      <v-switch
        color="primary"
        v-model="show.adset"
        flat
        label="Segmentación"
      ></v-switch>

      <v-switch
        color="primary"
        v-model="show.ad"
        flat
        label="Anuncio"
      ></v-switch>

      <v-switch
        color="primary"
        v-model="show.form"
        flat
        label="Formulario"
      ></v-switch>
    </div>
    <section v-if="loading">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="5"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>Cargando datos...</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </section>
    <section v-else-if="data.length > 0">
      <!-- Tabla -->
      <table v-if="showTable" class="py-4 grey lighten-5 custom-table">
        <thead>
          <tr>
            <template v-for="header in headers">
              <th
                v-if="header.show"
                :key="header.text"
                :rowspan="header.rowspan"
                :colspan="header.subHeaders?.length"
                :class="header.class"
                class="font-weight-regular"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.sortable"
                  @click="
                    orderByField(header.value, getSortOrder(header.value))
                  "
                  >{{ iconBySort(header.value) }}</v-icon
                >
              </th>
            </template>
          </tr>
          <tr v-if="hasSubHeaders">
            <th
              v-for="subHeader in subHeaders.subHeaders"
              :key="subHeader.value"
              class="caption"
              :class="subHeader.class"
            >
              <v-icon
                @click="
                  orderByField(
                    `${subHeaders.value}.${subHeader.value}`,
                    getSortOrder(`${subHeaders.value}.${subHeader.value}`)
                  )
                "
                dense
                >{{
                  iconBySort(`${subHeaders.value}.${subHeader.value}`)
                }}</v-icon
              >
              {{ subHeader.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(type, typeIndex) in data">
            <tr
              v-for="(row, rowIndex) in type.rows"
              :key="`type-${typeIndex}-row-${rowIndex}`"
              class="tr_custom"
            >
              <!-- Group By type -->
              <td
                v-if="rowIndex === 0"
                class="td-custom lastRow"
                :rowspan="type.rows.length"
              >
                <div class="mx-auto" :class="getClass({ key: 'type' })">
                  {{ capitalizeFirstLetter(type.type) }}
                  <p>
                    <v-chip color="primary">
                      {{ type.total }}
                    </v-chip>
                  </p>
                </div>
              </td>
              <!-- Other fields -->
              <td
                v-for="(header, headerIndex) in getCells.slice(1)"
                :key="`type-${typeIndex}-row-${rowIndex}-header-${headerIndex}`"
                class="td-custom"
                :class="[
                  { lastRow: rowIndex === type.rows.length - 1 },
                  getClass({ parent: header.parent, key: header.value }),
                  {
                    'total-info':
                      !isNaN(getCellValue(row, header.value)) &&
                      getCellValue(row, header.value) !== 0
                  }
                ]"
              >
                <div
                  class="d-flex align-center"
                  @click="
                    !isNaN(getCellValue(row, header.value)) &&
                    getCellValue(row, header.value) !== 0
                      ? getLeads(row, header.value)
                      : null
                  "
                >
                  <div class="">
                    <AvatarSource
                      v-if="header.value === 'source'"
                      :name="getCellValue(row, header.value)"
                    />
                    <AvatarMedium
                      v-else-if="header.value === 'medium'"
                      :name="getCellValue(row, header.value)"
                    />
                  </div>
                  <span class="px-1">{{
                    getCellValue(row, header.value) || "-"
                  }}</span>
                  <span class="percentStyle grey--text text--darken-1">{{
                    getPercent(
                      getCellValue(row, header.value),
                      header.value === "total" ? type.total : row.total
                    )
                  }}</span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <!-- Grafica -->
      <graphicCampaignReportV2
        v-else
        class="graphic mb-15 pb-10 py-5 mx-10"
        :valuesGraphics="data"
        ref="graphic"
      ></graphicCampaignReportV2>
    </section>
    <div v-else align="center">
      <v-alert :value="true">
        No se han encontrado resultados...
      </v-alert>
      <div align="center">
        <v-img
          center
          contain
          lazy-src="https://res.cloudinary.com/capital28-investments/image/upload/v1640032741/Capital%2028/Common/no_result_lead.png"
          max-height="500"
          max-width="375"
          src="https://res.cloudinary.com/capital28-investments/image/upload/v1640032741/Capital%2028/Common/no_result_lead.png"
        >
        </v-img>
      </div>
    </div>
    <ListLeadsByReportDetail
      :leads="leads"
      :dialog="dialog"
      :loading="loadingLeadList"
      @setDialog="value => (dialog = value)"
    >
    </ListLeadsByReportDetail>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dateRange from "@/components/reports-kpis/component/dateRange.component.vue";
import createPdf from "@/pdf/campaign-report-PDF.js";
import graphicCampaignReportV2 from "./component/graphicCampaignReportV2.vue";
import ListLeadsByReportDetail from "./component/ListLeadsByReportDetail";
import AvatarSource from "../common/AvatarSource.vue";
import AvatarMedium from "../common/AvatarMedium.vue";

export default {
  components: {
    dateRange,
    graphicCampaignReportV2,
    ListLeadsByReportDetail,
    AvatarSource,
    AvatarMedium
  },
  data() {
    return {
      leads: [],
      dialog: false,
      loadingLeadList: false,
      rotate: false,
      showTable: true,
      sort: {
        by: "total",
        order: "asc"
      },
      loading: false,
      show: {
        type: false,
        source: true,
        campaign: false,
        medium: false,
        adset: false,
        ad: false,
        form: false
      }, // meter los nameHeader para cambiar el valor de show y tambien se podria usar para saber porque criterios quiere agrupar
      filter: {
        date: {
          start: null,
          end: null
        },
        zones: [],
        sources: [],
        campaigns: []
      },
      data: [],
      headers: [
        {
          text: "Tipo",
          sortable: false,
          value: "type",
          class: "primary white--text",
          rowspan: 2,
          show: true,
          groupable: true
        },
        {
          text: "Fuente",
          sortable: true,
          value: "source",
          class: "primary white--text",
          rowspan: 2,
          show: true
        },
        {
          text: "Campaña",
          value: "campaign",
          class: "primary white--text",
          rowspan: 2,
          show: false
        },
        {
          text: "Segmentación",
          value: "adset",
          class: "primary white--text",
          rowspan: 2,
          show: false
        },
        {
          text: "Anuncio",
          value: "ad",
          class: "primary white--text",
          rowspan: 2,
          show: false
        },
        {
          text: "Formulario",
          value: "form",
          class: "primary white--text",
          rowspan: 2,
          show: false
        },
        {
          text: "Medio",
          value: "medium",
          class: "primary white--text",
          rowspan: 2,
          show: false
        },
        {
          text: "Leads",
          sortable: true,
          value: "total",
          class: "primary white--text",
          rowspan: 2,
          show: true
        },
        {
          text: "Activos",
          sortable: false,
          value: "active",
          align: "center",
          subHeaders: [
            {
              text: "TOF",
              value: "tof",
              class: "gradient-active deep-purple--text text--darken-4"
            },
            {
              text: "MOF",
              value: "mof",
              class: "gradient-active deep-purple--text text--darken-4"
            },
            {
              text: "BOF",
              value: "bof",
              class: "gradient-active deep-purple--text text--darken-4"
            }
          ],
          class:
            "ma-0 px-2 py-4 gradient-active deep-purple--text text--darken-4",
          show: true
        },
        {
          text: "Ventas",
          sortable: true,
          value: "finished",
          align: "center",
          class: "yellow lighten-5 brown--text text--darken-4",
          rowspan: 2,
          show: true
        },
        {
          text: "Descartados",
          sortable: true,
          value: "discarded",
          align: "center",
          class: "grey lighten-2 grey--text text--darken-4",
          rowspan: 2,
          show: true
        }
      ],
      itemClasses: {
        type: "",
        source: "row-table-color subtitle-2 body-2",
        campaign: "row-table-color body-2",
        adset: "row-table-color body-2",
        ad: "row-table-color body-2",
        form: "row-table-color body-2",
        medium: "row-table-color body-2",
        total: "font-weight-bold row-table-color",
        active:
          "font-weight-bold gradient-active deep-purple--text text--darken-4",
        finished:
          "font-weight-bold yellow lighten-5 brown--text text--darken-4",
        discarded: "font-weight-bold grey lighten-2 grey--text text--darken-4"
      },
      fetch: []
    };
  },
  mounted() {
    this.fetchDataByReport();
    this.getCampaigns();
  },
  watch: {
    show: {
      handler: function(val) {
        this.headers.forEach(header => {
          if (header.value === "type") {
            header.text = val.type ? "Tipo" : "Todos";
          } else {
            header.show =
              val[header.value] === undefined ? true : val[header.value];
          }
        });
        this.mapData();
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      campaignReport: state => state.reports.campaignReport,
      filters: state => state.leads.filters,
      leadsList: state => state.reports.leadListByComertialReport
    }),
    ...mapGetters("leads", {
      //sources: "getSources",
      zones: "getZones",
      sources: "getSources",
      mediums: "getMediums"
    }),
    ...mapGetters("reports", ["campaignList"]),
    hasSubHeaders() {
      return this.headers.some(header => header.subHeaders);
    },
    subHeaders() {
      // Encuentra el header con subHeaders y devuelve esos subHeaders
      const headerWithSubs = this.headers.find(header => header.subHeaders);
      return headerWithSubs || {};
    },
    getCells() {
      const cells = [];
      this.headers.forEach(header => {
        if (header.subHeaders) {
          return cells.push(
            ...header.subHeaders.map(subHeader => {
              return {
                text: subHeader.text,
                parent: header.value,
                value: `${header.value}.${subHeader.value}`
              };
            })
          );
        }
        if (header.show) cells.push({ value: header.value, text: header.text });
        return;
      });
      return cells;
    }
  },
  methods: {
    ...mapActions({
      getReport: "reports/fetchDataByCampaignReport",
      fetchfilters: "leads/fetchGetLeadsFilters",
      getListLeads: "reports/fetchListLeadsByDetailReport",
      getCampaigns: "reports/getCampaigns"
    }),
    async fetchDataByReport() {
      this.loading = true;
      await this.getReport(this.handlerFilters());
      this.fetch = this.campaignReport;
      this.mapData();
      this.orderByField(this.sort.by, this.sort.order);
      this.loading = false;
    },
    handlerFilters() {
      const newFilter = {};
      if (this.filter.zones.length > 0) {
        newFilter.zones = this.filter.zones;
      }
      if (this.filter.sources.length > 0) {
        newFilter.sources = this.filter.sources;
      }
      if (this.filter.campaigns.length > 0) {
        newFilter.campaigns = this.filter.campaigns;
      }
      if (this.filter.date.start && this.filter.date.end) {
        newFilter.date = this.filter.date;
      }
      return newFilter;
    },
    getClass({ parent, key }) {
      if (this.existInHeader(key)) return this.itemClasses[key];
      return this.itemClasses[parent];
    },
    existInHeader(key) {
      return this.headers.some(header => header.value === key);
    },
    getCellValue(row, key) {
      const levesl = key.split(".");
      if (levesl.length === 1) {
        if (typeof row[key] === "string")
          return this.capitalizeFirstLetter(row[key]);
        return row[key];
      }
      if (typeof row[levesl[0]][levesl[1]] === "string")
        return this.capitalizeFirstLetter(row[levesl[0]][levesl[1]]);
      return row[levesl[0]][levesl[1]];
    },
    getPercent(count, total) {
      if (
        isNaN(count) ||
        isNaN(total) ||
        Number(count) === 0 ||
        Number(total) === 0
      ) {
        return "";
      }

      const percent = ((count / total) * 100).toFixed(2);
      return `(${percent})%`;
    },
    mapData() {
      const {
        type: typeLvl,
        source: sourceLvl,
        campaign: campaignLvl,
        adset: adsetLvl,
        ad: adLvl,
        form: formLvl,
        medium: mediumLvl
      } = this.show;
      const result = [];
      this.fetch.forEach(item => {
        const { type, source, campaign, adset, ad, form, medium } = item;
        const typeValue = ["manual", "automatic"].includes(type)
          ? type
          : "Indefinido";
        const sourceValue = sourceLvl && source ? source : "N/A";
        const campaignValue = campaignLvl && campaign ? campaign : "N/A";
        const adsetValue = adsetLvl && adset ? adset : "N/A";
        const adValue = adLvl && ad ? ad : "N/A";
        const formValue = formLvl && form ? form : "N/A";
        const mediumValue = mediumLvl && medium ? medium : "N/A";

        const key = this.keyGroup(this.show, {
          sourceValue,
          campaignValue,
          adsetValue,
          adValue,
          formValue,
          mediumValue
        });

        if (
          result.findIndex(
            item => item.type === this.keyType(typeLvl, typeValue)
          ) < 0
        )
          result.push({
            type: this.keyType(typeLvl, typeValue),
            total: 0,
            rows: []
          });

        const typeIndex = result.findIndex(
          item => item.type === this.keyType(typeLvl, typeValue)
        );
        result[typeIndex].total += item.total;
        if (result[typeIndex].rows.findIndex(item => item.id === key) < 0)
          result[typeIndex].rows.push({
            id: key,
            type: this.keyType(typeLvl, typeValue),
            source: sourceValue,
            campaign: campaignValue,
            adset: adsetValue,
            ad: adValue,
            form: formValue,
            medium: mediumValue,
            total: 0,
            active: { tof: 0, mof: 0, bof: 0 },
            finished: 0,
            discarded: 0
          });

        const rowIndex = result[typeIndex].rows.findIndex(
          item => item.id === key
        );
        result[typeIndex].rows[rowIndex].total += item.total;
        result[typeIndex].rows[rowIndex].active.tof += item.tof;
        result[typeIndex].rows[rowIndex].active.mof += item.mof;
        result[typeIndex].rows[rowIndex].active.bof += item.bof;
        result[typeIndex].rows[rowIndex].finished += item.ventas;
        result[typeIndex].rows[rowIndex].discarded += item.descartados;
      });
      // order by type: online, offline, indefinido
      if (typeLvl) {
        result.sort((a, b) => {
          const order = ["Online", "Offline", "Indefinido"];
          return order.indexOf(a.type) - order.indexOf(b.type);
        });
      }
      this.data = result;
    },
    keyType(typeLvl, typeValue) {
      const mapType = {
        manual: "Offline",
        automatic: "Online",
        Indefinido: "Indefinido"
      };
      return typeLvl ? mapType[typeValue] : "total";
    },
    keyTypeReverse(typeValue) {
      const mapType = {
        Offline: "manual",
        Online: "automatic"
      };
      return mapType[typeValue] || "N/A";
    },
    keyGroup(
      lvls,
      {
        sourceValue,
        campaignValue,
        adsetValue,
        adValue,
        formValue,
        mediumValue
      }
    ) {
      const {
        source: sourceLvl,
        campaign: campaignLvl,
        adset: adsetLvl,
        ad: adLvl,
        form: formLvl,
        medium: mediumLvl
      } = lvls;
      return [
        sourceLvl ? sourceValue : "",
        campaignLvl ? campaignValue : "",
        adsetLvl ? adsetValue : "",
        adLvl ? adValue : "",
        formLvl ? formValue : "",
        mediumLvl ? mediumValue : ""
      ]
        .filter(Boolean)
        .join("-");
    },
    orderByField(field, sortOrder = "asc") {
      this.sort.by = field;
      this.sort.order = sortOrder;
      this.data.forEach(item => {
        item.rows.sort((a, b) => {
          let valueA = this.getCellValue(a, field);
          let valueB = this.getCellValue(b, field);
          if (typeof valueA === "string") valueA = valueA.toUpperCase();
          if (typeof valueB === "string") valueB = valueB.toUpperCase();

          if (sortOrder === "asc") {
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
          } else if (sortOrder === "desc") {
            return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
          }

          return 0;
        });
      });
    },
    iconBySort(key) {
      if (this.sort.by !== key) return "mdi-format-line-spacing";
      return this.sort.order === "asc"
        ? "mdi-format-vertical-align-top"
        : "mdi-format-vertical-align-bottom";
    },
    getSortOrder(key) {
      if (this.sort.by !== key) return "asc";
      return this.sort.order === "asc" ? "desc" : "asc";
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    cleanFilters() {
      this.$refs.dateRange.resetDateRange();
      this.filter.zones = [];
      this.filter.sources = [];
      this.filter.campaigns = [];
      this.fetchDataByReport();
    },
    makePdf() {
      try {
        createPdf(this.getCells, this.data, this.filter);
      } catch (e) {
        return this.$snotify.error(
          "Hubo un problema al generar el PDF, intentalo mas tarde",
          "Error :(",
          {
            timeout: 4000
          }
        );
      }
    },
    rotateGraphic() {
      this.rotate = !this.rotate;
      this.$refs.graphic.rotateGraphic();
    },
    async getLeads(row, key) {
      const mapFilter = {};
      if (this.show.source) {
        mapFilter.sources = this.sources
          .map(source => {
            if (source.name === row.source) return source._id;
          })
          .filter(Boolean)[0];
      }
      if (this.show.medium) {
        mapFilter.medium = this.mediums
          .map(medium => {
            if (medium.name === row.medium) return medium._id;
          })
          .filter(Boolean)[0];
      }
      if (this.show.campaign) {
        mapFilter.campaigns = row.campaign;
      }
      if (this.show.adset) {
        mapFilter.adset = row.adset;
      }
      if (this.show.ad) {
        mapFilter.ad = row.ad;
      }
      if (this.show.form) {
        mapFilter.form = row.form;
      }
      if (this.show.type) {
        mapFilter.registration = this.keyTypeReverse(row.type);
      }
      const type = key.split(".")[1] || key;

      this.loadingLeadList = true;
      this.dialog = true;
      await this.getListLeads({
        ...this.handlerFilters(),
        ...mapFilter,
        type,
        typeReport: "campaign"
      });
      this.leads = this.leadsList;
      this.loadingLeadList = false;
    }
  }
};
</script>
<style>
.custom-table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
.tr_custom {
  border-bottom: 1px solid #e0e0e0;
}
.td-custom {
  padding: 0.25rem;
  border-right: 1px solid #e0e0e0;
}
.tr_custom:hover .td-custom {
  background-color: #dae9fa;
}

.gradient-active {
  background: rgb(228, 196, 233, 1);
}
.lastRow {
  border-bottom: 3px ridge white;
}
.graphic {
  width: 90%;
  height: 100%;
}

.flip-icon {
  transform: scaleX(-1);
}

.list_options {
  display: flex;

  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
