<template>
  <HelperMessage>
    <v-avatar :size="size">
      <img :src="src" :alt="message" />
    </v-avatar>

    <template #message>
      <div
        class="flex items-center justify-center w-full h-full rounded-full bg-gray-200"
      >
        <span class="text-gray-500">{{ message }}</span>
      </div>
    </template>
  </HelperMessage>
</template>

<script>
import HelperMessage from "./HelperMessage.vue";

export default {
  name: "AvatarTraffic",
  components: {
    HelperMessage
  },
  props: {
    message: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "30px"
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
