<template>
  <AvatarTraffic :message="message" :src="src" size="20" />
</template>

<script>
import AvatarTraffic from "./AvatarTraffic.vue";
import { mapGetters } from "vuex";
export default {
  name: "AvatarMedium",
  components: {
    AvatarTraffic
  },
  props: {
    id: String,
    name: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("leads", ["getMediums"]),
    src() {
      const mediums = this.getmediums();

      return mediums ? mediums.media?.src : "";
    },
    message() {
      const mediums = this.getmediums();
      return mediums ? mediums.name : this.name;
    }
  },
  methods: {
    getmediums() {
      // buscar en el store la fuente con el id o name en caso de que no se encuentre el id
      return this.getMediums.find(mediums => {
        if (mediums._id === this.id || mediums.name === this.name)
          return mediums;
      });
    }
  }
};
</script>
