import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function createPdf(headers, data, filters) {
  const doc = new jsPDF("l", "mm", [677.3, 381]);
  const columns = getHeaders(headers);
  const columnStyles = getColumnStyles(columns);

  data.forEach((element, index) => {
    if (index > 0) {
      doc.addPage();
    }
    const body = getBody(element.rows);
    addTitle(doc, "REPORTE CAMPAÑAS / PLATAFORMAS");
    addFilters(doc, filters);
    addBannerType(doc, element.type);
    autoTable(doc, {
      columns,
      body,
      startY: 70,
      theme: "grid",
      styles: {
        fontSize: 18,
        font: "Lato-Bold",
        overflow: "linebreak"
      },
      columnStyles,
      headStyles: { textColor: "FFFFFF", fillColor: "262D3C", halign: "center" }
    });
  });

  doc.save("campaignReport.pdf");
}

function getBody(data) {
  const body = data.map(item => {
    const { active, ...others } = item;
    const { tof, mof, bof } = active;
    return {
      tof,
      mof,
      bof,
      ...others
    };
  });
  return body;
}

function getHeaders(data) {
  let slice = 0;
  if (data[0].value === "type") {
    slice = 1;
  }
  const headers = data.slice(slice).map(head => {
    const { value, text, parent } = head;

    return {
      dataKey: parent ? value.split(".")[1] : value,
      header: text
    };
  });
  return headers;
}
function addTitle(doc, text) {
  doc.setFont("Lato-Bold", "normal");
  doc.setTextColor("262D3C");
  doc.setFontSize(30);
  doc.text(text, 20, 20);
}
const mapWidth = {
  source: {
    8: 70,
    7: 150,
    6: 260
  },
  campaign: {
    8: 150,
    7: 140
  },
  medium: {
    8: 70,
    7: 140
  },
  total: {
    8: 50,
    7: 50,
    6: 80
  }
};
function getColumnStyles(headers) {
  const styles = {
    source: {
      cellWidth: mapWidth.source[headers.length - 1],
      textColor: "262D3C",
      lineColor: [231, 231, 231]
    },
    campaign: {
      cellWidth: mapWidth.campaign[headers.length - 1],
      textColor: "262D3C",
      lineColor: [231, 231, 231]
    },
    medium: {
      cellWidth: mapWidth.medium[headers.length - 1],
      textColor: "262D3C",
      lineColor: [231, 231, 231]
    },
    total: {
      cellWidth: mapWidth.total[headers.length - 1],
      textColor: "262D3C",
      lineColor: [231, 231, 231]
    },
    tof: {
      cellWidth: 50,
      halign: "center",
      textColor: [49, 27, 146],
      fillColor: [252, 244, 252],
      lineColor: [231, 231, 231]
    },
    mof: {
      cellWidth: 50,
      halign: "center",
      textColor: [49, 27, 146],
      fillColor: [252, 244, 252],
      lineColor: [231, 231, 231]
    },
    bof: {
      cellWidth: 50,
      halign: "center",
      textColor: [49, 27, 146],
      fillColor: [252, 244, 252],
      lineColor: [231, 231, 231]
    },
    ventas: {
      cellWidth: 80,
      halign: "center",
      textColor: [62, 39, 35],
      fillColor: [255, 253, 231],
      lineColor: [231, 231, 231]
    },
    descartados: {
      cellWidth: 80,
      halign: "center",
      textColor: [0, 0, 0],
      fillColor: [224, 224, 224],
      lineColor: [231, 231, 231]
    }
  };
  return styles;
}
function addFilters(doc, { date, zones, sources }) {
  doc.setFontSize(20);

  if (!date.start || !date.end) {
    doc.text("Fechas: Todas", 20, 55);
  } else {
    doc.text(
      "Fechas: " +
        new Date(date.start).toLocaleDateString() +
        " ~ " +
        new Date(date.end).toLocaleDateString(),
      20,
      55
    );
  }

  if (zones.length > 0) {
    doc.text("Desarrollo: " + zones.join(","), 20, 45);
  } else {
    doc.text("Desarrollo: Todos", 20, 45);
  }

  if (sources.length > 0) {
    doc.text("Fuente: " + sources.join(""), 20, 35);
  } else {
    doc.text("Fuente: Todos", 20, 35);
  }
}

function addBannerType(doc, type) {
  doc.autoTable({
    startY: 60,
    body: [
      [
        {
          content: type,
          styles: {
            halign: "center",
            fontSize: 18,
            font: "Lato-Bold",
            fillColor: "7D8491",
            textColor: [255, 255, 255],
            cellWidth: 260
          }
        }
      ]
    ]
  });
}
export default createPdf;
