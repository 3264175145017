<template>
  <AvatarTraffic :message="message" :src="src" size="20" />
</template>

<script>
import AvatarTraffic from "./AvatarTraffic.vue";
import { mapGetters } from "vuex";
export default {
  name: "AvatarSource",
  components: {
    AvatarTraffic
  },
  props: {
    id: String,
    name: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("leads", ["getSources"]),
    src() {
      const source = this.getSource();

      return source ? source.media?.src : "";
    },
    message() {
      const source = this.getSource();
      return source ? source.name : this.name;
    }
  },
  methods: {
    getSource() {
      // buscar en el store la fuente con el id o name en caso de que no se encuentre el id
      return this.getSources.find(source => {
        if (source._id === this.id || source.name === this.name) return source;
      });
    }
  }
};
</script>
